<template>
  <v-dialog v-model="dialog" max-width="1000" persistent transition="dialog-bottom-transition">
    <v-card tile>
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="account && account.db_name">Tasks Configuradas - {{ account.db_name }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-container v-if="account && account.dag && account.dag.tasks_branches" fluid>
        <!-- configured tasks_branches -->
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-alert v-if="!account.dag.tasks_branches || !Object.keys(account.dag.tasks_branches).length" class="mx-6 my-2" type="info" dense outlined
            >Não há tasks configuradas.</v-alert
          >
          <div>
            <div v-for="(value, key) in account.dag.tasks_branches" :key="key">
              <v-row no-gutters class="px-6">
                <v-col cols="5" class="my-2">
                  <v-text-field hide-details class="mr-4" disabled label="Task" :value="key" required outlined />
                </v-col>
                <v-col cols="7" class="my-2">
                  <v-text-field
                    v-model="account.dag.tasks_branches[key]"
                    :rules="featureRules"
                    :disabled="!editable"
                    hide-details
                    label="Feature"
                    required
                    outlined
                  >
                    <template v-slot:append-outer>
                      <v-icon v-if="editable" class="justify-center mx-1" color="error" @click="removeTask(key)">mdi-close-box</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-form>
        <!-- action buttons -->
        <v-card class="ml-6 mx-6 my-4" flat>
          <v-btn v-if="!editable" color="primary" outlined block @click="editable = true"><v-icon class="mr-1">mdi-pencil</v-icon> Editar</v-btn>
          <v-btn v-if="editable" class="mr-2" color="primary" outlined @click="editable = false"><v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn>
          <v-btn v-if="editable" class="mr-2" :disabled="!valid" color="success" @click="updateTasks"><v-icon class="mr-1">sync</v-icon> Atualizar</v-btn>
          <v-btn v-if="editable" class="mr-2" :disabled="!valid" color="info" @click="openAddTask"><v-icon class="mr-1">mdi-plus</v-icon> Adicionar Task</v-btn>
          <v-tooltip v-if="editable" right color="error">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="error" v-bind="attrs" @click="cleanTasks" v-on="on"> <v-icon class="mr-2">mdi-alert</v-icon> Limpar Tasks </v-btn>
            </template>
            <span>A propriedade "dag.tasks_branches" será substituída por um objeto vazio.</span>
          </v-tooltip>
        </v-card>
      </v-container>
    </v-card>

    <add-task v-if="account" :dialog="addTask" :tasks="tasks" :db_name="account.db_name" @close="closeAddTask" />
  </v-dialog>
</template>

<script>
import {
  QUERY_ACCOUNT_FEATURE_BRANCH,
  MUTATION_CLEAN_TASKS_BRANCHES,
  MUTATION_UPDATE_TASKS_BRANCHES,
  MUTATION_REMOVE_TASK
} from '@/modules/feature_branch/graphql'

export default {
  components: {
    AddTask: () => import('@/modules/feature_branch/components/AddTask')
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    accountId: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    editable: false,
    featureRules: [v => !!v || 'Campo não pode ser vazio'],
    valid: false,
    // add task props
    addTask: false,
    tasks: [],
    account: null
  }),
  apollo: {
    accountFeatureBranch: {
      query: QUERY_ACCOUNT_FEATURE_BRANCH,
      variables() {
        return { id: this.accountId }
      },
      fetchPolicy: 'network-only',
      update({ accountFeatureBranch }) {
        this.account = accountFeatureBranch
      }
    }
  },
  methods: {
    close() {
      this.editable = false
      this.$emit('close')
    },
    refresh() {
      this.$apollo.queries.accountFeatureBranch.refresh()
    },
    openAddTask() {
      this.tasks = Object.keys(this.account.dag.tasks_branches)
      this.addTask = true
    },
    closeAddTask() {
      this.tasks = []
      this.addTask = false
      this.editable = false
      this.refresh()
    },
    async removeTask(key) {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_REMOVE_TASK,
          variables: {
            db_name: this.account.db_name,
            task_key: key
          }
        })
        this.refresh()
        this.$snackbar({ message: 'Task removida com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        this.$snackbar({ message: 'Falha ao remover task', snackbarColor: '#F44336' })
      }
    },
    async updateTasks() {
      if (!this.$refs.form.validate()) {
        return this.$snackbar({ message: 'Dados inválidos, verifique novamente', snackbarColor: '#F44336' })
      }

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_TASKS_BRANCHES,
          variables: {
            db_name: this.account.db_name,
            tasks_branches: this.account.dag.tasks_branches
          }
        })
        this.$snackbar({ message: 'Tasks atualizadas com sucesso', snackbarColor: '#2E7D32' })
        this.editable = false
        this.refresh()
      } catch (err) {
        this.$snackbar({ message: 'Falha ao atualizar tasks', snackbarColor: '#F44336' })
      }
    },
    async cleanTasks() {
      const db_names = [this.account.db_name]
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_CLEAN_TASKS_BRANCHES,
          variables: {
            db_names
          }
        })
        this.$snackbar({ message: 'Tasks removidas com sucesso', snackbarColor: '#2E7D32' })
        this.editable = false
        this.refresh()
      } catch (err) {
        this.$snackbar({ message: 'Falha ao limpar tasks', snackbarColor: '#F44336' })
      }
    }
  }
}
</script>
